import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import {
  EventNavbar,
  EventStreamHeader,
  Speakers,
  EventFooter,
  ProtectedRoute,
  About,
  Agendas,
  Terms,
  SEO,
  Modal,
  Button
} from 'components';
import { graphql, navigate } from 'gatsby';
import { LocalContext } from 'context';

const LivestreamTemplate = ({ data }) => {
  const { frontmatter: event } = data.markdownRemark;
  const {
    user,
    setSelectedEvent,
    theme,
    registeredRecentlyWithOneStep,
    setRegisteredRecentlyWithOneStep
  } = useContext(LocalContext);

  useEffect(() => {
    setSelectedEvent({
      eid: event.eid,
      name: event.name,
      colors: event.colors,
      slug: event.slug
    });
    return () => setSelectedEvent(null);
  }, [event.name]);

  return (
    <ProtectedRoute
      eid={event.eid}
      eventSlug={event.slug}
      eventIsHidden={event.hidden}
      eventStatus={event.status}>
      <>
        <SEO
          pageSpecificTitle={`${event.name} Livestream`}
          pageSpecificDescription={event.description
            .replace(/<[^>]*>?/gm, '')
            .replace('?', '? ')
            .replace('.', '. ')}
          pageSpecificThumbnail={event.socialThumbnail}
          pageSpecificThumbnailAlt={event.name}
        />
        <EventNavbar
          colors={event.colors}
          eid={event.eid}
          eventDate={event.date}
          eventSlug={event.slug}
        />
        <EventStreamHeader
          colors={event.colors}
          eid={event.eid}
          eventSlug={event.slug}
          eventStatus={event.status}
          eventDates={event.date}
          eventName={event.name}
          eventSubtitle={event.subtitle}
          eventLocation={event.location}
          eventStartTime={event.start}
          eventEndTime={event.end}
          eventDescription={event.description}
          eventBanner={event.banner}
          isParticipantsEnabled={event.isParticipantsEnabled}
          isChatEnabled={event.isChatEnabled}
          isQAndAEnabled={event.isQAndAEnabled}
          isPollsEnabled={event.isPollsEnabled}
          chatEnabled={event.chatEnabled}
          footNote={event.streamFootNote}
          endScreenImg={event.endScreenImg}
          // streamUrl={event.streamUrl}
        />
        <Agendas agendas={event.agendas} colors={event.colors} speakers={event.speakers} />
        {/* <SpeakersContainer $theme={theme}>
          <Speakers speakers={event.speakers} colors={event.colors} id="speakers" />
        </SpeakersContainer> */}
        <About invert />
        <Terms />
        <EventFooter />
        <Modal
          hideCloseButton
          openWithoutTrigger={registeredRecentlyWithOneStep}
          disableCloseOnClickOutside
          modalContent={
            <OneStepRegistered $theme={theme}>
              Your registration is confirmed.
              <br /> Thank you!
              <br />
              <Button
                type="button"
                shine
                whileHover={{ scale: 1.025 }}
                whileTap={{ scale: 0.95 }}
                style={{
                  color: '#FFFFFF',
                  backgroundColor: theme.tertiary,
                  width: '250px',
                  height: '3.5rem',
                  marginBottom: '0rem',
                  lineHeight: '1.2rem',
                  marginTop: '1.75rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                onClick={() => setRegisteredRecentlyWithOneStep(false)}>
                Continue to event
              </Button>
            </OneStepRegistered>
          }
        />
      </>
    </ProtectedRoute>
  );
};

const SpeakersContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 3rem 1rem;
  position: relative;
  width: 100%;
  @media only screen and (min-width: 1150px) {
    padding: 6rem 0;
  }
`;

const OneStepRegistered = styled.div`
  background: ${({ $theme }) => $theme.primary};
  border-radius: 0.625rem;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: bold;
  height: auto;
  justify-content: center;
  line-height: 1.275em;
  max-width: 100%;
  opacity: 0.9;
  padding: 2.5rem 1rem;
  text-align: center;
  width: 500px;
  button {
    margin: 0 auto;
  }
`;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      frontmatter {
        eid
        name
        slug
        status
        slug
        status
        colors {
          primary
          secondary
          tertiary
        }
        name
        title
        subtitle
        seoThumbnail {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        socialThumbnail {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        description
        date
        location
        isParticipantsEnabled
        isChatEnabled
        isQAndAEnabled
        isPollsEnabled
        start
        end
        startTime
        endTime
        eventHighlights {
          id
          url
          thumb {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          title
          description
        }
        banner {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
          }
        }
        endScreenImg {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        speakers {
          id
          name
          position
          fbLink
          twLink
          linkedinLink
          emailLink
          shortSummary
          summary
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        agendas {
          day
          title
          slots {
            orderNumber
            title
            speaker
            speakerImages {
              imageOrder
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            summary
          }
        }
      }
    }
  }
`;

export default LivestreamTemplate;
